<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="list">
            <div class="item" @click="$router.push({ path: '/culturalPersonnel', query: { id: item.id } })" v-for="(item, index) in list" :key="index">
                <div>
                    <div class="img" :style="{ backgroundColor: colorArr[index % 3] }">{{ item.name.substring(0, 1) }}</div>
                    <span class="title">{{ item.name }}({{ item.memberNum }}人)</span>
                </div>
            </div>
        </div>
        <el-pagination class="pagination" v-if="total" :current-page="pageNum" :page-size="pageSize" :total="total" @current-change="currentChange" background layout="prev, pager, next,jumper"></el-pagination>
    </div>
</template>

<script>
export default {
    name: 'about',
    data() {
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '文化家族',
                },
            ],
            colorArr: ['rgba(82, 196, 26, 0.5)', 'rgba(250, 173, 20, 0.5)', 'rgba(24, 144, 255, 0.5)'],
            pageNum: 1,
            pageSize: 16,
            total: 0,
            list: [],
        };
    },
    created() {
        this.getData();
    },
    watch: {
        typeIndex() {
            this.pageNum = 1;
            this.getData();
        },
    },
    methods: {
        getData() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                type: 1,
            };
            this.$hao.getTalentFamilyList(params).then((res) => {
                this.list = res.rows;
                this.total = res.total;
            });
        },
        currentChange(val) {
            //滚动到页面顶部
            window.scrollTo(0, 0);
            this.pageNum = val;
            this.getData();
        },
    },
};
</script>

<style scoped lang="less">
.list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;
    .item {
        margin-bottom: 56px;
        cursor: pointer;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .img {
            font-weight: 700;
            font-size: 40px;
            text-align: center;
            color: #ffffff;
            width: 175px;
            height: 175px;
            line-height: 175px;
            border-radius: 100%;
            filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
        }

        .title {
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #333333;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin-top: 13px;
            width: 90%;
            .ellipsisLine(1);
        }
    }
}
</style>
